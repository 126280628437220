document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector("#pricing-page-container")) return;

  let monthly = document.getElementById("monthly");
  let annually = document.getElementById("annually");
  let proPlan = document.getElementById("pro-plan");

  let toggleBtn = document.getElementById("toggle-btn");
  let personal_price = document.getElementById("personal-price");

  let annualPlan = true;

  const toggleIt = () => {
    if (annualPlan) {
      console.log("switching to monthly");
      annually.classList.remove("bg-indigo-600");
      annually.classList.remove("text-white");
      annually.classList.add("bg-white");
      annually.classList.add("text-gray-600");

      monthly.classList.add("bg-indigo-600");
      monthly.classList.add("text-white");
      monthly.classList.remove("bg-white");
      monthly.classList.remove("text-gray-600");

      personal_price.innerText = "$4.43";
      annualPlan = false;
      proPlan.setAttribute("data-plan", "monthly");
    } else {
      console.log("switching to annually");

      monthly.classList.remove("bg-indigo-600");
      monthly.classList.remove("text-white");
      monthly.classList.add("bg-white");
      monthly.classList.add("text-gray-600");

      annually.classList.add("bg-indigo-600");
      annually.classList.add("text-white");
      annually.classList.remove("bg-white");
      annually.classList.remove("text-gray-600");
      personal_price.innerText = "$4.00";
      annualPlan = true;
      proPlan.setAttribute("data-plan", "annually");
    }
  };

  toggleBtn.addEventListener("click", () => {
    toggleIt();
  });
});
